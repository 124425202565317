.aboutshila {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .container-aboutshila {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-top: -60px;
      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        margin-top: 80px;
      }
      .heading-aboutshila {
        width: 100%;
        margin-top: -5%;
        @media (max-width: 768px) {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .logoshila {
            width: 100%; 
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            margin-bottom: 25px;
            @media (max-width: 768px){
              width: 90%;
            }
        }
        .gambarshila{
            width: 50%;
            justify-content: center;
            align-content: center;
            align-items: center;
            @media (max-width: 768px) {
              width: 90%;
              margin-left: 2%;
              margin-top: -20%;
              margin-bottom: -10%;
            }
        }
        }
        .desk {
          font-size: "Poppins", sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.5em;
          color: rgb(44, 44, 44);
          text-align: center;
          @media (max-width: 768px) {
            width: 80%;
            text-align: center;
          }
        }
        .button {
          display: flex;
          margin-top: 20px;
          justify-content: center;
          align-content: center;
          margin-top: 20px;
          @media (max-width: 768px) {
            width: 100%;
            justify-content: center;
          }
          .wa {
            font-size: 16px;
            justify-content: center;
            padding: 15px 100px;
            position: absolute;
            z-index: 1;
            cursor: pointer;
            background-color: #1f565c;
            border-width: 1px;
            border-color: #1f565c;
            border-radius: 25px;
            border-style: solid;
            color: white;
            &:hover {
              margin-top: 3px;
              margin-left: 5px;
              padding: 15px 100px;
              border-radius: 25px;
              background-color: #ffffff;
              border-width: 1px;
              border-color: #1f565c;
              border-style: solid;
              color: #1f565c;
              transition: transform 0.3s ease-in-out;
            }
            @media (max-width: 768px) {
              width: 80%;
              padding: 10px 50px;
            }
          }
        }
      }
      .gambaraboutshila {
        display: flex;
        // justify-content: center;
        position: relative;
        align-items: center;
        width: 100%;
        background-image: url("../../media/fasad/hannam.webp");
        background-size: 75%;
        background-repeat: no-repeat;
        background-position: center;
        height: 120vh;
        @media (max-width: 768px) {
          width: 98%;
          flex-direction: column;
          justify-content: end;
          height: 70vh;
          margin-top: -5%;
          margin-bottom: -20px;
        }
        &:active {
          transform: scale(1.1); /* Adjust scaling factor */
        }
        }
      }
    