@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px){
    margin-top: 30px;
  }
  .judul-newlaunch1 {
    font-family: "Poppins";
    color: #334442;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
    .judul1 {
      font-weight: 400;
      font-size: 22px;
    }
    .judul2 {
      font-weight: 600;
      font-size: 50px;
      letter-spacing: 3px;
      @media (max-width: 768px) {
        font-size: 28px;
      }
    }
    .judul3 {
      font-weight: 600;
      font-size: 41px;
      margin-top: -15px;
      letter-spacing: 0.5px;
      @media (max-width: 768px) {
        font-size: 20px;
        width: 100%;
        letter-spacing: 0px;
        margin-top: -5px;
      }
    }
  }

  .containercard-apart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    @media (max-width: 768px) {
      flex-direction: column;
      overflow: hidden;
    }
    .card {
      width: 25%;
      margin-left: 15px;
      margin-top: 10px;
      background-color: #fff;

      padding-bottom: 10px;
      hr {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        width: 90%;
      }
      @media (max-width: 768px) {
        width: 90%;
        margin-bottom: 20px;
        margin-left: 0px;
        height: auto;
      }
      .imagecard {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
      .imagecard1 {
        width: 100%;
      }
      .slick-next::before,
      .slick-prev::before {
        font-family: "slick";
        font-size: 30px;
      }
      .slick-list {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }

      .slick-dots {
        position: absolute; /* Make dots absolute for positioning within slides */
        bottom: 10px; /* Adjust vertical positioning */
        left: 50%; /* Center dots horizontally */
        transform: translateX(-50%); /* Center dots precisely */
        z-index: 1; /* Ensure dots are above image content */
      }
      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
      .slick-dots {
        li {
          margin: 0;
        }
      }

      .container-rumah {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 10px;
        .nama-rumah {
          width: 100%;
          font-family: "poppins" sans-serif;
          font-size: 30px;
          font-weight: 500;
          color: #1f565c;
        }
      }
      .gridmap {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        .icon {
          margin-top: 5px;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
        }
        .namalokasi{
        width: 100%;
        font-size: 18px;
        color: #4b4b4b;
        }
      }

      .containercicilan {
        width: 100%;
        margin-top: -5px;
        display: flex;
        background-color: white;

        .judulkartu {
          width: 50%;
          padding: 10px;
          margin: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .startfrom {
            color: #1f565c;
            font-family: "Poppins" sans-serif;
            font-size: 20px;
            font-weight: 400;
          }
        }
        .cicilansumban {
          width: 50%;
          padding: 10px;
          border-radius: 10px 0px 0 10px;
          color: #1f565c;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background-color: white;
          .angka {
            font-family: "Poppins";
            font-size: 20px;
            font-weight: 500;
          }
          .ket {
            font-family: "Poppins";
            font-size: 10px;
            padding-top: 10px;
            font-weight: 500;
          }
        }
      }
      hr {
        width: 95%;
      }
      .gridspek {
        padding-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        //background: rgb(90,131,103);
        //padding-top: 15px;
        //padding-bottom: 15px;
        border: 1px #c6995e;
        border-radius: 0px 0px 10px 10px;
        gap: 10px;
        span {
          color: #091c34;
            display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 5px;
          padding-right: 10px;
          @media (max-width: 768px) {
            padding-right: 20px;
            font-size: 12px;
          }
        }
      }
      .clusterbutton {
        display: flex;
        justify-content: center;
        //padding-top: 25px;
        
        .cluster-whatsapp {
          display: inline-block;
          padding: 10px 100px;
          cursor: pointer;
          border: solid 1px white;
          border-radius: 20px;
          background-color: #1f565c; 
          color: #ffffff;
          font-size: 16px;
          &:hover {
            background-color: transparent;
            color: #1f565c;
            border-color: #1f565c;
            
        }
        }
      }
    }
  }
}
